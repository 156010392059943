import {
    IRestaurantList,
    IRestaurantSelectModal,
} from "../../../Portal/Groups/interfaces";

import {
    Divider,
    Box,
    Typography,
    CircularProgress,
    IconButton,
} from "@mui/material";
import {
    OutlinedInput,
    List,
    ListItem,
    ListItemText,
    InputAdornment,
    Icon,
} from "@mui/material";
import useRequest from "api/useRequest";
import ProjectContext, { IProjectContext } from "app/App/context";
import { restaurantsFetch } from "app/Booking/Restaurant/actions";
import trans from "helpers/trans";
import usePrevious from "hooks/usePrevious";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Modal,
    ModalTitle,
    ModalContent,
    ModalActions,
    RequestMessage,
} from "ui";

let timeout: ReturnType<typeof setTimeout>;

const RestaurantSelectModal: FC<IRestaurantSelectModal> = ({
    title,
    handleChangeSearch,
    search,
    onClickRestaurant,
    onCloseModal,
}) => {
    const [searchVal, setSearchRestaurantValue] = useState<string>(search);
    const prevValue = usePrevious(searchVal);

    const { project } = useContext(ProjectContext) as IProjectContext;
    const appName = useSelector(
        (state: any) =>
            state.app.environments.find((item: any) => item.module === project)
                ?.env
    );

    const [data, setData] = useState<any>([]);
    const { currentData, status, isError, isLoading, request, cancel } =
        useRequest();

    useEffect(() => {
        if (!isLoading && status === 200) {
            setData(currentData.data);
        }
    }, [currentData?.data, isLoading, status]);

    useEffect(() => {
        if (prevValue === undefined || prevValue === searchVal) {
            return;
        }

        clearTimeout(timeout);
        cancel();

        if (searchVal.length > 0) {
            timeout = setTimeout(() => {
                handleRestaurant(searchVal);
            }, 500);
        } else {
            handleRestaurant(searchVal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancel, searchVal, prevValue]);

    const handleSearch = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setSearchRestaurantValue(e.target.value);
        handleChangeSearch(e.target.value);
    };

    const handleRestaurant = (val: string) => {
        if (val.length > 0) {
            request(
                restaurantsFetch(appName, {
                    params: { _search: val, _sort: { name: "asc" } },
                })
            );
        } else setData([]);
    };

    const handleClearInput = () => {
        setSearchRestaurantValue("");
    };
    const renderContent = () => {
        if (isLoading) return null;

        if (isError)
            return status === 404 ? (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans(
                        "admin.booking.restaurant.modal.restaurantModal.notFound"
                    )}
                </Typography>
            ) : (
                <RequestMessage status={status} />
            );

        return (
            <List component="nav" sx={{ pt: 0 }}>
                {data.map((item: IRestaurantList) => {
                    const fullAddress =
                        item.address && item.city
                            ? `${item.address}, ${item.city}`
                            : item.address || item.city || "";
                    return (
                        <Box key={`${item.id}`}>
                            <ListItem
                                button
                                onClick={(event) =>
                                    onClickRestaurant(event, item)
                                }
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <ListItemText primary={item.name} />
                                    <ListItemText
                                        secondary={item.uid}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                        }}
                                    />
                                    <ListItemText
                                        secondary={fullAddress}
                                        secondaryTypographyProps={{
                                            fontSize: 12,
                                        }}
                                    />
                                </Box>
                            </ListItem>
                            <Divider />
                        </Box>
                    );
                })}
            </List>
        );
    };
    const renderEndAdornment = () => {
        if (isLoading) {
            return (
                <InputAdornment position="end">
                    <CircularProgress size={20} />
                </InputAdornment>
            );
        }

        if (searchVal.length > 0) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClearInput}
                        sx={{
                            position: "absolute",
                            right: "0",
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                </InputAdornment>
            );
        }
    };
    return (
        <Modal
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={() => onCloseModal()}>{title}</ModalTitle>
            <ModalActions>
                <OutlinedInput
                    autoFocus
                    fullWidth
                    onChange={handleSearch}
                    placeholder={title}
                    size="small"
                    endAdornment={renderEndAdornment()}
                    startAdornment={
                        <InputAdornment position="start">
                            <Icon>search</Icon>
                        </InputAdornment>
                    }
                    value={searchVal}
                />
            </ModalActions>
            <ModalContent sx={{ pt: 0, px: 2 }}>{renderContent()}</ModalContent>
        </Modal>
    );
};

export default RestaurantSelectModal;
